import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { YellowSection } from "../components/common/section";

const BackgroundSection = () => {
  const data = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "bg_fyzio-praxis.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 2560
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <YellowSection parallax backgroundImage={data.backgroundImage}>
      <div
        className="text-right"
        data-sal="slide-up"
        data-sal-easing="easeIn"
        data-sal-duration="400"
        data-sal-delay="0"
      >
        <p className="mb-0 font-black text-display-sm md:text-display-lg lg:text-display-xl text-white uppercase">
          Die <span className="text-yellow">FYZIO</span> Praxis
        </p>
        <p className="mb-0 font-black text-body-xl md:text-display-xs lg:text-display-sm text-white uppercase">
          AB 2023 IN DER ORTENAU
        </p>
        <p className="mb-0 text-body-xl md:text-display-xs lg:text-display-sm text-white font-bold uppercase">
          Standort Lahr
        </p>
      </div>
    </YellowSection>
  );
};

export default BackgroundSection;
