import React from "react";
import BackgroundSection from "../components/backgroundSection";
import Contact from "../components/contact";
import Opening from "../components/opening";
import Hero from "../components/hero";
import Features from "../components/features";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ContentMobile from "../components/contentMobile";

const IndexPage = () => (
  <Layout nav={false}>
    <Seo />
    <Hero />
    <Opening />
    <ContentMobile />
    <Features />
    <BackgroundSection className="h-screen bg-left" />
    <Contact />
  </Layout>
);

export default IndexPage;
