import React from "react";
import { Parallax } from "react-scroll-parallax";

import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Logo from "../images/logo_fyzio.svg";
import Claim from "../images/claim_spring.svg";

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      opener: file(relativePath: { eq: "opener_bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      openerMobile: file(relativePath: { eq: "opener_bg_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className='bg-brand pt-6 pb-10 md:pt-12 md:pb-24'>
        <div className='container mx-auto relative'>
          <div className='grid grid-cols-10'>
            <div className='row-span-full col-span-10 lg:col-start-1 lg:col-span-4 self-center z-10'>
              <Parallax speed={5}>
                <img
                  src={Logo}
                  className='max-w-xs md:max-w-none md:w-full mx-auto -mb-10 md:mb-8'
                  alt='FYZIO – THERAPIE@HOME'
                  data-sal='zoom-in'
                  data-sal-easing='ease'
                  data-sal-duration='600'
                  data-sal-delay='700'
                />
                <div
                  className='px-4'
                  data-sal='fade'
                  data-sal-easing='ease'
                  data-sal-duration='1200'
                  data-sal-delay='1000'
                >
                  <p className='hidden md:block font-normal text-white'>
                    <strong className='font-bold'>
                      <i>FYZIO</i>
                    </strong>{" "}
                    ist der neue Anbieter im Bereich Physiotherapie. Das
                    Einzugsgebiet ist die komplette Ortenau. Der erste Standort
                    in Lahr in der Tullastraße 27 ist bereits geöffnet.
                    Langjährig erfahrene Therapeuten behandeln Dich in der
                    Praxis in Lahr oder machen sich auf den Weg zu Dir nach
                    Hause.
                  </p>
                  <p className='hidden md:block font-normal text-white'>
                    Mit der{" "}
                    <span className='uppercase'>
                      mobilen{" "}
                      <strong className='font-bold'>
                        <i>FYZIO-FLOTTE</i>
                      </strong>
                    </span>{" "}
                    besuchen wir Dich, wo auch immer Du uns brauchst. Wir
                    bringen alles mit, um eine effektive Behandlung
                    durchzuführen. Und die Vorteile liegen klar auf der Hand:
                  </p>
                  <ul className='hidden md:block mb-8 list-none font-normal text-white'>
                    <li>
                      <svg
                        className='w-6 h-6 mr-1.5 -mt-1 text-yellow flex-shrink-0 inline-block'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                      <span className='font-bold'>Schnelle Termine</span>
                      <br />
                      <span className='pl-6 ml-2'>innerhalb 48 Stunden</span>
                    </li>
                    <li className='font-bold'>
                      <svg
                        className='w-6 h-6 mr-1.5 -mt-1 text-yellow flex-shrink-0 inline-block'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                      Keine Fahrtzeit
                    </li>
                    <li className='font-bold'>
                      <svg
                        className='w-6 h-6 mr-1.5 -mt-1 text-yellow flex-shrink-0 inline-block'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                      Keine Parkplatzsuche
                    </li>
                    <li className='font-bold'>
                      <svg
                        className='w-6 h-6 mr-1.5 -mt-1 text-yellow flex-shrink-0 inline-block'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                      Zeitsparend und komfortabel
                    </li>
                    <li>
                      <svg
                        className='w-6 h-6 mr-1.5 -mt-1 text-yellow flex-shrink-0 inline-block'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                      <span className='font-bold'>An Deinem Ort</span>
                      <br />
                      <span className='pl-6 ml-2'>
                        in Deinem gewohnten Umfeld
                      </span>
                    </li>
                  </ul>
                  <div className='block lg:hidden col-span-10'>
                    <GatsbyImage
                      image={getImage(data.openerMobile)}
                      alt='Neu ab 2023'
                    />
                    <div className='text-center mb-8'>
                      <p className='mb-0 font-bold text-white text-body-lg xl:text-body-xl leading-none xl:leading-none'>
                        Kostenlose Service-Nummer
                      </p>
                      <p className='mb-0 font-bold text-display-sm xl:text-display-md leading-none xl:leading-none text-center text-yellow'>
                        0800 / 588 355 0
                      </p>
                    </div>
                  </div>
                  <AnchorLink
                    to='/#contact'
                    title='Jetzt schon Termine reservieren!'
                    duration='180000'
                    className='button px-4 py-3 font-bold text-body-xs sm:text-body-lg flex items-center justify-center text-center rounded-md bg-gradient-to-tl from-brand-500 via-brand-900 hover:from-brand-400 hover:via-brand-800 hover:to-brand-400 text-yellow font-semibold uppercase border-2 border-white transition-all duration-150 ease-linear'
                    stripHash
                  />
                </div>
              </Parallax>
            </div>
            <div className='row-span-full col-span-7 col-end-11 z-0'>
              <div className='grid grid-cols-10'>
                <div
                  className='col-span-10 relative'
                  data-sal='slide-up'
                  data-sal-easing='easeOutBack'
                  data-sal-duration='900'
                  data-sal-delay='400'
                >
                  <Parallax speed={8}>
                    <GatsbyImage
                      image={getImage(data.opener)}
                      alt='Neu ab 2023'
                      className='w-full z-10'
                    />
                    <div className='hidden lg:block w-full h-full absolute top-0 z-10'>
                      <div className='w-full h-full flex items-center justify-end'>
                        <div>
                          <div
                            className='text-center'
                            data-sal='slide-left'
                            data-sal-easing='ease'
                            data-sal-duration='1600'
                            data-sal-delay='1000'
                          >
                            <p className='mb-0 font-bold text-white text-body-lg xl:text-body-xl leading-none xl:leading-none'>
                              Kostenlose Service-Nummer
                            </p>
                            <p className='mb-0 font-bold text-display-sm xl:text-display-md leading-none xl:leading-none text-center text-yellow'>
                              0800 / 588 355 0
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Parallax>
                </div>

                <div className='col-span-8 col-start-3 -mt-10'>
                  <Parallax speed={8}>
                    <div>
                      <img
                        src={Claim}
                        className='w-full'
                        alt='Spring ins Leben zurück – Physiotherapie bei Dir zu Hause'
                        data-sal='flip-up'
                        data-sal-easing='ease'
                        data-sal-duration='1000'
                        data-sal-delay='1000'
                      />
                    </div>
                  </Parallax>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
