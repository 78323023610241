import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Logo from "../images/logo_fyzio.svg";
import FeatureItem from "./featureItem";
import { GradientSection } from "./common/section";
import { Parallax } from "react-scroll-parallax";

const Icons = () => {
  const data = useStaticQuery(graphql`
    {
      allFeaturesJson {
        nodes {
          id
          title
          icon {
            publicURL
          }
        }
      }
      thumbnail: file(relativePath: { eq: "video-thumbnail.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 768
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <GradientSection>
      <div className="grid grid-cols-5 gap-x-8">
        <div className="justify-center flex flex-col col-span-5 md:col-span-4">
          <Parallax speed={-2}>
            <h2 className="text-display-xs lg:text-display-lg text-center md:text-left uppercase font-ultra text-yellow">
              Wir sind überall
            </h2>
            <h3 className="text-body-lg md:text-display-xs text-center md:text-left uppercase font-bold text-white">
              Wo man uns braucht!
            </h3>
          </Parallax>
        </div>
        <div className="invisible md:visible flex-col flex justify-center">
          <img src={Logo} alt="FYZIO – THERAPIE@HOME" />
        </div>
      </div>
      <div className="mt-14">
        <Parallax speed={-2}>
          <div className="flex flex-wrap justify-center -mx-8">
            {data.allFeaturesJson.nodes.map((node) => (
              <FeatureItem
                key={node.id}
                icon={node.icon.publicURL}
                title={node.title}
              />
            ))}
          </div>
        </Parallax>
      </div>
    </GradientSection>
  );
};
export default Icons;
