import React from "react";
import PropTypes from "prop-types";

const FeatureItem = ({ icon, title, description }) => {
  return (
    <div className="feature-icon w-1/2 md:w-1/5 px-8">
      <img
        src={icon}
        alt="Feature Icon"
        className="w-full rounded-full shadow-xl mb-4"
      />
        <p className="text-body-xl text-white text-center">{title}</p>

    </div>
  );
};

FeatureItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default FeatureItem;
