import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CardMap = () => {
  const data = useStaticQuery(graphql`
    {
      map: file(relativePath: { eq: "maps_fyzio_lahr.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <>
      <div className='grid grid-cols-1 gap-8 md:grid-cols-12'>
        <div className='md:col-span-6 md:col-start-4 flex flex-col align-center justify-start'>
          <h3 className='mb-4 text-body-xl md:text-display-md lg:text-display-lg text-center uppercase font-black text-brand'>
            Vor Ort
          </h3>
          <a
            className='group p-4 lg:p-0 w-full sm:max-w-full lg:flex shadow-lg bg-brand hover:bg-brand-900 transition rounded-md text-white'
            href='https://goo.gl/maps/RoWR3wmvBMQ4kR3n7'
            target='_blank'
            rel='noreferrer'
          >
            <div className='h-28 mb-4 lg:mb-0 lg:h-auto lg:w-48 flex-none rounded lg:rounded-none lg:rounded-l-md overflow-hidden'>
              <GatsbyImage
                className='bg-cover w-full h-full rounded lg:rounded-none lg:rounded-l-md transition duration-500 group-hover:scale-110 overflow-hidden'
                image={getImage(data.map)}
                alt='Fyzio Lahr'
              />
            </div>
            <div className='p-0 lg:p-4 flex flex-col justify-between leading-normal'>
              <address className='not-italic mb-4'>
                <span className='uppercase text-yellow'>
                  <strong>Fyzio Praxis Ortenau</strong>
                </span>
                <br />
                Standort Lahr
                <br />
                Tullastraße 27
                <br />
                77933 Lahr
                <br />
              </address>
              <div className='uppercase font-bold text-yellow text-body-md md:text-body-xl lg:text-display-sm group-hover:opacity-90 transition'>
                Route <span className='ml-2'>➡</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};
export default CardMap;
