import React, { useState } from "react";
import { LightSection } from "../components/common/section";
import Logo from "../images/logo_fyzio.svg";
import { Parallax } from "react-scroll-parallax";
import axios from "axios";

const Contact = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/01a1e009-3a3c-4b27-a278-42043482ef2c",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Danke für Deine Nachricht!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <LightSection>
      <Parallax speed={5}>
        <h2 className="text-body-xl lg:text-display-lg text-center md:text-right uppercase font-ultra text-brand">
          Nimm Kontakt mit uns auf
        </h2>
        <h3 className="mb-16 text-body-sm md:text-display-xs uppercase font-bold text-brand text-center md:text-right">
          Wir freuen uns auf deine Nachricht!
        </h3>

        <div>
          <div className="bg-brand p-4 px-4 md:p-8 mb-6 shadow-xl">
            <form id="contact" acceptCharset="UTF-8" onSubmit={handleOnSubmit}>
              <div className="grid grid-cols-1 lg:gap-x-4 lg:grid-cols-4">
                <div className="text-white md:col-span-3">
                  <div className="sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="hidden lg:block">
                      <div className="ratio-box w-4/5 -mt-[45%] mx-auto p-8">
                        <div className="content p-8 rounded-full bg-brand border border-white">
                          <img
                            src={Logo}
                            className="w-full"
                            alt="FYZIO – THERAPIE@HOME"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="grid mb-2 sm:mb-0 gap-4 gap-y-2 grid-cols-1 md:grid-cols-5">
                        <div className="md:col-span-5">
                          <div className="formgroup relative z-0 w-full h-10 mb-5">
                            <input
                              type="text"
                              name="vorname"
                              id="vorname"
                              defaultValue=""
                              className="pt-3 pb-2 px-2 block w-full mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-1 focus:ring-yellow-600 focus:border-white border-white rounded"
                              placeholder=" "
                              required
                            />
                            <label
                              htmlFor="vorname"
                              className="absolute px-2 ml-2 duration-300 top-3 -z-1 origin-0 text-white bg-brand"
                            >
                              Vorname *
                            </label>
                          </div>
                        </div>

                        <div className="md:col-span-5">
                          <div className="formgroup relative z-0 w-full h-10 mb-5">
                            <input
                              type="text"
                              name="nachname"
                              id="nachname"
                              defaultValue=""
                              className="pt-3 pb-2 px-2 block w-full mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-1 focus:ring-yellow-600 focus:border-white border-white rounded"
                              placeholder=" "
                              required
                            />
                            <label
                              htmlFor="nachname"
                              className="absolute px-2 ml-2 duration-300 top-3 -z-1 origin-0 text-white bg-brand"
                            >
                              Nachname *
                            </label>
                          </div>
                        </div>
                        <div className="md:col-span-5">
                          <div className="formgroup relative z-0 w-full h-10 mb-5">
                            <input
                              type="text"
                              name="strasse"
                              id="strasse"
                              defaultValue=""
                              className="pt-3 pb-2 px-2 block w-full mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-1 focus:ring-yellow-600 focus:border-white border-white rounded"
                              placeholder=" "
                            />
                            <label
                              htmlFor="strasse"
                              className="absolute px-2 ml-2 duration-300 top-3 -z-1 origin-0 text-white bg-brand"
                            >
                              Straße und Hausnr.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="grid gap-4 gap-y-2 grid-cols-1 md:grid-cols-5">
                        <div className="md:col-span-2">
                          <div className="formgroup relative z-0 w-full h-10 mb-5">
                            <input
                              type="text"
                              name="PLZ"
                              id="plz"
                              defaultValue=""
                              className="pt-3 pb-2 px-2 block w-full mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-1 focus:ring-yellow-600 focus:border-white border-white rounded"
                              placeholder=" "
                            />
                            <label
                              htmlFor="plz"
                              className="absolute px-2 ml-2 duration-300 top-3 -z-1 origin-0 text-white bg-brand"
                            >
                              PLZ
                            </label>
                          </div>
                        </div>
                        <div className="md:col-span-3">
                          <div className="formgroup relative z-0 w-full h-10 mb-5">
                            <input
                              type="text"
                              name="ort"
                              id="ort"
                              defaultValue=""
                              className="pt-3 pb-2 px-2 block w-full mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-1 focus:ring-yellow-600 focus:border-white border-white rounded"
                              placeholder=" "
                            />
                            <label
                              htmlFor="ort"
                              className="absolute px-2 ml-2 duration-300 top-3 -z-1 origin-0 text-white bg-brand"
                            >
                              Ort
                            </label>
                          </div>
                        </div>
                        <div className="md:col-span-5">
                          <div className="formgroup relative z-0 w-full h-10 mb-5">
                            <input
                              type="email"
                              name="E-Mail"
                              id="email"
                              defaultValue=""
                              className="pt-3 pb-2 px-2 block w-full mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-1 focus:ring-yellow-600 focus:border-white border-white rounded"
                              placeholder=" "
                              required="required"
                            />
                            <label
                              htmlFor="email"
                              className="absolute px-2 ml-2 duration-300 top-3 -z-1 origin-0 text-white bg-brand"
                            >
                              E-Mail *
                            </label>
                          </div>
                        </div>
                        <div className="md:col-span-5">
                          <div className="formgroup relative z-0 w-full h-10 mb-5">
                            <input
                              type="text"
                              name="telefon"
                              id="telefon"
                              defaultValue=""
                              className="pt-3 pb-2 px-2 block w-full mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-1 focus:ring-yellow-600 focus:border-white border-white rounded"
                              placeholder=" "
                              required
                            />
                            <label
                              htmlFor="telefon"
                              className="absolute px-2 ml-2 duration-300 top-3 -z-1 origin-0 text-white bg-brand"
                            >
                              Telefon/Mobil *
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-2 lg:col-span-3">
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-3">
                        <div className="hidden lg:block text-center -mt-8">
                          <p className="mb-0 font-bold text-body-lg">
                            Kostenlose Service-Nummer
                          </p>
                          <p className="mb-0 font-bold text-display-sm leading-none text-center text-yellow">
                            0800 / 588 355 0
                          </p>
                        </div>
                        <div className="mb-8 col-span-2">
                          <div className="block w-full h-1 mb-6 rounded bg-yellow"></div>
                          <div className="inline-block w-full text-right mb-4">
                            <label
                              htmlFor="default-checkbox"
                              className="chekbox-label text-right h-8 ml-2 font-medium text-white"
                            >
                              <input
                                id="default-checkbox"
                                type="checkbox"
                                defaultValue=""
                                className="float-right ml-4 w-8 h-8 text-brand bg-brand accent-yellow rounded border border-white focus:ring-1 focus:ring-yellow-600 focus:border-white checked:border checked:border-white"
                                required="required"
                              />
                              <span className="pt-[4px] text-body-md">
                                Ich habe die Datenschutzrichtline gelesen und
                                stimme dieser zu.
                              </span>
                            </label>
                          </div>
                          <small className="text-left leading-tight">
                            Rechtlicher Hinweis: Sie stimmen zu, dass Ihre
                            Angaben aus dem Kontaktformular zur Beantwortung
                            Ihrer Anfrage erhoben und verarbeitet werden. Die
                            Daten werden nach abgeschlossener Bearbeitung Ihrer
                            Anfrage gelöscht. Sie können Ihre Einwilligung
                            jederzeit für die Zukunft per E-Mail an
                            info@fyzio.de widerrufen.
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <select
                    defaultValue={"DEFAULT"}
                    className="block appearance-none w-full h-12 pt-3 pb-2 px-2 mb-5 border border-white bg-brand text-white pr-8 rounded shadow leading-tight focus:ring-1 focus:ring-yellow-600 focus:border-white"
                    name="Rubrik"
                  >
                    <option value="DEFAULT" disabled>
                      Rubrik
                    </option>
                    <option>Termin</option>
                    <option>Mobile Therapie</option>
                    <option>Training</option>
                    <option>Produkte</option>
                    <option>Job-Anfrage</option>
                  </select>
                  <div className="formgroup">
                    <label htmlFor="message" className="hidden">
                      Your message
                    </label>
                    <textarea
                      id="message"
                      name="Nachricht"
                      rows="12"
                      className="block p-2.5 w-full text-white bg-brand rounded border border-white focus:ring-1 focus:ring-yellow-600 focus:border-white placeholder-white"
                      placeholder="Nachricht … *"
                    ></textarea>
                  </div>
                  <button
                    id="submit"
                    type="submit"
                    disabled={serverState.submitting}
                    className="w-full px-6 py-3 mt-3 text-yellow border border-white bg-brand-800 hover:bg-brand-700 hover:text-brand transition-all duration-150 ease-linear rounded-lg shadow outline-none focus:ring-1 focus:ring-yellow-600 focus:border-white"
                  >
                    <span className="block -rotate-2 text-display-xs font-black uppercase">
                      <i>Senden</i>
                    </span>
                  </button>
                  {serverState.status && (
                    <div className={!serverState.status.ok ? "errorMsg" : ""}>
                      <p className="text-yellow font-bold text-center mt-4">
                        {serverState.status.msg}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Parallax>
    </LightSection>
  );
};
export default Contact;
