import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { LightSection } from "./common/section";
import { Parallax } from "react-scroll-parallax";
import scrollTo from "gatsby-plugin-smoothscroll";
import CardMap from "../components/cardMap";

const Opening = () => {
  const data = useStaticQuery(graphql`
    {
      eyecatcher: file(relativePath: { eq: "cars_eyecatcher.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <LightSection>
      <div className=''>
        <div className='lg:grid gap-16 md:grid-cols-10 mb-16 lg:mb-32'>
          <div className='lg:col-span-4 flex flex-col align-center justify-start'>
            <div className='mb-8 lg:mb-0 lg:mt-0 my-0 lg:my-auto'>
              <h2 className='text-display-xs lg:text-display-lg text-center md:text-left uppercase font-ultra text-brand lg:leading-none'>
                Es ist so weit.
              </h2>

              <h3 className='text-body-lg md:text-display-xs text-center md:text-left uppercase font-bold text-brand'>
                FYZIO Therapie @Home
                <br className='inline-block md:hidden lg:inline-block' /> ist ab
                sofort für Dich da!
              </h3>
            </div>
          </div>
          <div className='col-span-10 md:col-span-6 flex flex-col content-center justify-center'>
            <div className='my-auto'>
              <p>
                Buche Deinen Termin unter der kostenlosen Service-Nummer: 0800 /
                588 355 0 oder über das Kontaktformular. Wir freuen uns schon
                jetzt auf Deine Nachricht und grüßen Dich herzlich aus der
                Ortenau.
              </p>
              <p className='mb-0'>Dein Fyzio-Team</p>
            </div>
          </div>
        </div>
        <h3 className='mt-5 mb-4 text-body-xl md:text-display-md lg:text-display-lg text-center uppercase font-black text-brand'>
          Jetzt Termin buchen
        </h3>
        <div className='grid grid-cols-1 gap-8 md:grid-cols-10 mb-16'>
          <a
            href='tel:+498005883550'
            className='flex bg-brand hover:bg-brand-900 p-2 md:p-4 shadow-lg rounded-md col-span-10 md:col-span-4 md:col-start-2 h-20 md:h-auto transition-all duration-150 ease-linear outline-none focus:ring-1 focus:ring-yellow-600'
          >
            <div className='m-auto'>
              <div className='text-center'>
                <p className='mb-0 font-bold text-white text-body-md lg:text-body-lg xl:text-body-xl leading-none xl:leading-none'>
                  Über die <br /> Kostenlose Service-Nummer
                </p>
                <p className='mb-0 font-bold text-display-xs lg:text-display-sm xl:text-display-md leading-none xl:leading-none text-center text-yellow'>
                  0800 / 588 355 0
                </p>
              </div>
            </div>
          </a>
          <button
            onClick={() => scrollTo("#contact")}
            className='flex bg-brand hover:bg-brand-900 p-2 md:p-4 shadow-lg rounded-md col-span-10 md:col-span-4 h-20 md:h-auto transition-all duration-150 ease-linear outline-none focus:ring-1 focus:ring-yellow-600'
          >
            <div className='m-auto'>
              <div className='text-center sal-animate'>
                <p className='mb-0 font-bold text-white text-body-md lg:text-body-lg xl:text-body-xl leading-none xl:leading-none'>
                  Über das <br />
                </p>
                <p className='mb-0 font-bold text-display-xs lg:text-display-sm xl:text-display-md leading-none xl:leading-none text-center text-yellow uppercase'>
                  Kontaktformular
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
      <CardMap></CardMap>
      <Parallax speed={3}>
        <div
          className='mt-20 mx-auto relative'
          data-sal='slide-left'
          data-sal-delay='600'
          data-sal-duration='600'
          data-sal-easing='easeOutQuart'
        >
          <GatsbyImage
            image={getImage(data.eyecatcher)}
            alt='Die Fahrzeuge der mobilen FYZIO-Flotte'
          />
        </div>
        <div>
          <h3 className='mt-5 text-body-lg sm:text-display-sm lg:text-display-lg text-center uppercase font-black text-brand'>
            Der neue Eyecatcher in der Region
          </h3>
        </div>
      </Parallax>
    </LightSection>
  );
};
export default Opening;
