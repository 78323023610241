import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      opener: file(relativePath: { eq: "opener_bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      openerMobile: file(relativePath: { eq: "opener_bg_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      headMobile: file(relativePath: { eq: "head-content-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div className='sm:hidden'>
      <div className='bg-brand pt-10 pb-2'>
        <div className='container mx-auto relative'>
          <div className=''>
            <div className='self-center z-10'>
              <GatsbyImage
                image={getImage(data.headMobile)}
                alt='Neu ab 2023'
                className='col-span-10'
              />
              <div className='mt-8'>
                <p className='font-normal text-body-xl text-white'>
                  <strong className='font-bold'>
                    <i className='text-yellow'>FYZIO</i>
                  </strong>{" "}
                  ist der neue Anbieter im Bereich Physiotherapie. Das
                  Einzugsgebiet ist die komplette Ortenau. Der erste Standort in
                  Lahr in der Tullastraße 27 ist bereits geöffnet. Langjährig
                  erfahrene Therapeuten behandeln Dich in der Praxis in Lahr
                  oder machen sich auf den Weg zu Dir nach Hause.
                </p>
                <p className='font-normal text-body-xl text-white'>
                  Mit der{" "}
                  <span className='uppercase'>
                    mobilen{" "}
                    <strong className='font-bold'>
                      <i>FYZIO-FLOTTE</i>
                    </strong>
                  </span>{" "}
                  besuchen wir Dich, wo auch immer Du uns brauchst. Wir bringen
                  alles mit, um eine effektive Behandlung durchzuführen.
                  <br />
                  <br />
                  <strong>Und die Vorteile liegen klar auf der Hand:</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-yellow pt-4 pb-10'>
        <div className='container mx-auto relative'>
          <ul className='mb-0 list-none text-display-xs font-normal text-black'>
            <li>
              <svg
                className='w-6 h-6 mr-1.5 -mt-1 text-brand flex-shrink-0 inline-block'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='font-bold'>Schnelle Termine</span>
              <br />
              <span className='pl-6 ml-2'>innerhalb 48 Stunden</span>
            </li>
            <li className='font-bold'>
              <svg
                className='w-6 h-6 mr-1.5 -mt-1 text-brand flex-shrink-0 inline-block'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                  clipRule='evenodd'
                ></path>
              </svg>
              Keine Fahrtzeit
            </li>
            <li className='font-bold'>
              <svg
                className='w-6 h-6 mr-1.5 -mt-1 text-brand flex-shrink-0 inline-block'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                  clipRule='evenodd'
                ></path>
              </svg>
              Keine Parkplatzsuche
            </li>
            <li className='font-bold'>
              <svg
                className='w-6 h-6 mr-1.5 -mt-1 text-brand flex-shrink-0 inline-block'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                  clipRule='evenodd'
                ></path>
              </svg>
              Zeitsparend und komfortabel
            </li>
            <li>
              <svg
                className='w-6 h-6 mr-1.5 -mt-1 text-brand flex-shrink-0 inline-block'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='font-bold'>An Deinem Ort</span>
              <br />
              <span className='pl-6 ml-2'>in Deinem gewohnten Umfeld</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Hero;
